import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("bootstrap/dist/css/bootstrap.min.css")
require("@fortawesome/fontawesome-free/css/all.min.css")
require("ionicons/dist/css/ionicons.min.css")
require("icon-kit/dist/css/iconkit.min.css")
require("perfect-scrollbar/css/perfect-scrollbar.css")
require("../themes/theme-kit/dist/css/theme.min.css")

const $ = require("jquery")
window.jquery = window.$ = window.jQuery = $
global.jquery = global.$ = global.jQuery = $
const Popper = require("popper.js/dist/umd/popper.min.js")
window.Popper = Popper
global.Popper = Popper
require("bootstrap/dist/js/bootstrap.min.js")
